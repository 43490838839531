<template src="./ShowAdd.html"></template>

<script>

export default {
  name: "ShowAdd",

  data: () => ({

    valid: true,
    show_id:0,

    name:"",
    author:"",
    genre:"",
    description:"",
    picture:"",
    video:"",
    pictures_count:0,

    tag_status_id:70,
    time:"",
    link:"",
    age:"",
    culture_id: 0,

    result:"",

  }),

  // computed: {
  //
  //   layout_id: function () {
  //
  //     if (this.selected>-1){
  //       return this.list[this.selected].id;
  //     }
  //     return false;
  //   },
  //
  // },

  created() {

    if (this.$store.getters.isAuthenticated){

      //this.loadShows();
      //this.loadLayouts();

    }


  },


  methods:{

    addShow(){

      // TODO заблочить кнопку отправки

      let post_data = {

        id: 0,

        name:           this.name,
        author:         this.author,
        genre:          this.genre,
        description:    this.description,
        picture:        this.picture,
        video:          this.video,
        pictures_count: this.pictures_count,
        tag_status_id:  this.tag_status_id,
        time:           this.time,
        link:           this.link,
        age:            this.age,
        culture_id:     this.culture_id
      }
      console.log(post_data);

      let pr2 = this.$store.state.api.addShow(post_data);

      pr2.then( data =>{

        console.log(data);

        this.result = this.name+ " - Спектакль "+data+" добавлен"

        // resp : id only
        //
        // this.layouts = data;
        // this.layouts_ready = true;
      })


    },




  }
}
</script>

<style scoped>

</style>
